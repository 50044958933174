import Service from "../Service";
import Vue from "vue";
const resource = "Zon/";

export default {
  save(ded, requestID) {
    return Service.post(resource + "save", ded, {
      params: { requestID: requestID },
    });
  },
  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
      params: {
        requestID: requestID,
      },
    });
  },

  listzon(search, RequestID){
    return Service.post(resource + "listzon",{},{
    params:{Search: search, RequestID: RequestID}
  });
 
},

GetUrllistzona2(){

  return Vue.prototype.$http.defaults.baseURL + "/Zon/listzon";
},


 };
