<template>
	<div>
		<v-card>
			<v-col>
				<v-row>
					<v-col>
						<s-select-definition
							full
							label="Seleccione Semana"
							:def="1310"
							v-model="SpwWeek"
						>
              			</s-select-definition>
					</v-col>
					<v-col cols="12">
						<v-data-table
							:items="packingboats"
						>
							<template v-slot:header >
								<thead>
									<tr>
										<th>Real</th>
										<th>Lunes</th>
										<th>Martes</th>
										<th>Miercoles</th>
										<th>Jueves</th>
										<th>Viernes</th>
										<th>Sábado</th>
										<th>Domingo</th>
										<th rowspan="2">Total</th>
										<th rowspan="2">Contenedor</th>
										<th rowspan="2">Pallet</th>
									</tr>
									<tr>
										<th>Variedad</th>
										<th v-for="item in days">
											{{item}}
										</th>
										<!-- <th>Lunes</th>
										<th>Martes</th>
										<th>Miercoles</th>
										<th>Jueves</th>
										<th>Viernes</th>
										<th>Sábado</th>
										<th>Domingo</th> -->
									</tr>
								</thead>
							</template>

							<template v-slot:item="{ item, index }">
								<tr>
									<td>{{item.Variety}}</td>
									<td>{{item.SdpMonday}}</td>
									<td>{{item.SdpTuesday}}</td>
									<td>{{item.SdpWednesday}}</td>
									<td>{{item.SdpThursday}}</td>
									<td>{{item.SdpFriday}}</td>
									<td>{{item.SdpSaturday}}</td>
									<td>{{item.SdpSunday}}</td>
									<td>{{item.SpwTotal}}</td>
									<td>{{item.SpwContainer}}</td>
									<td>{{item.SpwPallet}}</td>
								</tr>
								<!-- <tr>
									<td>1212</td>
									<td>23323</td>
								</tr> -->
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-col>
		</v-card>
	</div>
</template>

<script>
	import _sSlePackingBoatService from "@/services/Sale/SlePackingBoatService";
import Variety from '@/views/TechnicalAssistance/Variety/Variety';
	export default {
		data() {
			return {
				headers: [
					{text:"Real", value: ""},
					{text:"Lunes", value: ""},
					{text:"Martes", value: ""},
					{text:"Miercoles", value: ""},
					{text:"Jueves", value: ""},
					{text:"Viernes", value: ""},
					{text:"Sábado", value: ""},
					{text:"Domingo", value: ""},
					{text:"Total", value: ""},
					{text:"Contenedor", value: ""},
					{text:"Pallet", value: ""},
				],
				filter: {},
				packingboats: [],
				days: [],
				SpwWeek: 0,
			}
		},

		watch: {
			SpwWeek() {
				this.loadData();
			}
		},

		methods: {
			loadData() {
				this.filter.SpwWeek = this.SpwWeek;
				_sSlePackingBoatService.pagination({ filter: this.filter}, this.$fun.getUserID())
					.then((resp) => {
						this.packingboats = resp.data.data;
					});

				_sSlePackingBoatService.daylist(this.SpwWeek, this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200){
							this.days = resp.data;
						}
					});
			}
		},

		created () {
			this.loadData();
		},
	}
</script>