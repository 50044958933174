<template>
  <div>
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <v-btn
          block
          color="primary"
          @click="dialog = true"
        >
          Resumen Semanal
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="s-col-form" cols="12" lg="12" md="12" sm="12">
        <project-week
          :isAdmin="true"
          @rowSelected="rowSelected($event)"
        >
        </project-week>
      </v-col>
      <v-col class="s-col-form" cols="12" lg="12" md="12" sm="12">
        <project-week-detail  v-if="spwID!=0"
          :isAdmin="true" 
          :SpwID="spwID" 
        >
        </project-week-detail>
      </v-col>
    </v-row>
    

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
    >
      <v-card>
        <s-toolbar
          label="EMBARQUE"
          color="light-blue darken-3"
          dark
          close
          @close="dialog = false">
        </s-toolbar>
        <packing-boat></packing-boat> 
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProjectWeek from "./ProjectWeek.vue";
import ProjectWeekDetail from "./ProjectWeekDetail.vue";
import PackingBoat from "../PackingBoat/PackingBoat.vue";

export default {
  components: {
    ProjectWeek,
    ProjectWeekDetail,
    PackingBoat
  },

  data() {
    return {
      parentID: 0,
      spwID: 0,
      dialog: false,
    };
  },

  methods: {
    rowSelected(rows) {
      if(rows[0] != null){
        this.spwID = rows[0].SpwID;
      }
      else this.spwID=0;
    },
  },
};
</script>
