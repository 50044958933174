<template>
  <s-crud
    @returnObject="refresh($event)"
    no-border
    title="Programación Semanal"
    :config="this.config"
    @save="saveDed($event)"
    add
    edit
    @rowSelected="rowSelected($event)"
    remove
    :filter="filter"
    height="180"
    sortable
  >
    <template scope="props">
      <v-container
        style="padding-top: 0px"
        v-if="props.item != null"
        class="pb-0"
      >
        <v-row>
          <v-col cols="12" class="s-col-form" lg="4" md="6" sm="12">
            <s-select-definition
              v-model="props.item.TypeCultive"
              label="Cultivo"
              :def="1173"
            ></s-select-definition>
          </v-col>
          <v-col cols="12" class="s-col-form" lg="4" md="6" sm="12">
            <s-select-variety
              v-model="props.item.VrtID"
              label="Variedad"
              :cultiveID="props.item.TypeCultive"
            ></s-select-variety>
          </v-col>
          <v-col cols="12" class="s-col-form" lg="4" md="6" sm="12">
            <s-select-definition
              v-model="props.item.TypeCrop"
              label="Tipo Cultivo"
              :def="1172"
            ></s-select-definition>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="s-col-form" lg="8" md="6" sm="12">
            <s-selected-zona
              v-model="props.item.ZonID"
              label="Zona"
            ></s-selected-zona>
          </v-col>
          <v-col cols="12" class="s-col-form" lg="4" md="6" sm="12">
            <s-select-type-certification
              v-model="props.item.TcID"
              label="Tipo Certificación"
              :text="props.item.TcDescription"
            ></s-select-type-certification>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="s-col-form" lg="4" md="6" sm="12">
            <s-date
              v-model="props.item.SpwDateBegin"
              label="Fecha Inicio"
            ></s-date>
          </v-col>
         <!--  <v-col cols="12" class="s-col-form" lg="4" md="6" sm="12">
            <s-date
              v-model="props.item.SpwDateEnd"
              label="Fecha Fin"
            ></s-date>
          </v-col> -->
          <v-col 
            cols="12" 
            class="s-col-form" 
            lg="4" 
            md="6" 
            sm="12"
          >
            <s-select-definition
              v-model="props.item.SpwStatus"
              label="Estado"
              :def="1306"
            ></s-select-definition>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-slot:SpwStatus="{ row }">
      <v-chip
        style="margin: 0px"
        x-small
        :color="row.SpwStatus == 1 ? 'success' : 'error'"
      >
        {{ row.SpwStatus == 1 ? "Activo" : "Inactivo" }}
      </v-chip>
    </template>

    <!-- <template v-slot:filter>
        <v-col cols="12" lg="4" sm="6" md="12">
          <s-date
            label="Año"
          ></s-date>
        </v-col>
    </template> -->
  </s-crud>
</template>

<script>
import _sDefinitionDetail from "@/services/Sale/SleProjectionWeekService";
import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety.vue";
import SSelectTypeCertification from "@/components/Utils/Certification/sSelectTypeCertification.vue";
import SSelectedZona from "@/components/TecnicalAssistence/ProducersFarms/sSelectedZona.vue";
import SText from "@/components/Utils/SText.vue";
export default {
  components: {
    SSelectVariety,
    SSelectTypeCertification,
    SSelectedZona,
    SText,
  },
  props: {
    defID: 0,
    defDescription: "",
  },
  data: () => ({
    spwID: 0,
    config: {
      title: "Definiciones Detalles",
      service: _sDefinitionDetail,
      model: {
        SpwID: "ID",
        SpwWeek: "int",
        DedDescription: "string",
        VrtDescription: "string",
        ZonDescription: "string",
        TcDescription: "string",
        SpwStatus: "status",
         SpwQuantity: "decimal",
        SecStatus: "status",
        SpwDateBegin: "date",
        SpwDateEnd: "date",
      },
      headers: [
        {
          text: "ID",
          value: "SpwID",
          width: "5%",
          align: "end",
        },
        { text: "Semana N°", value: "SpwWeek", width: "8%"},
        { text: "Fecha Inicio", value: "SpwDateBegin", width: "8%"},
        { text: "Fecha Fin", value: "SpwDateEnd", width: "8%"},
        {
          text: "Cultivo",
          value: "DedDescription",
          width: "20%",
        },
        {
          text: "Variedad",
          value: "VrtDescription",
          width: "20%",
        },
        {
          text: "Zona",
          value: "ZonDescription",
          width: "20%",
          align: "center",
        },
        {
          text: "Certificación",
          value: "TcDescription",
          width: "20%",
          align: "center",
        },
        {
          text: "Estado",
          value: "SpwStatus",
          width: "5%",
          align: "center",
        },
      ],
    },
    filter: {}
  }),

  methods: {
    rowSelected(items) {
      if (items.length > 0) this.spwID = items[0].SpwID;
      console.log(items);
      this.$emit("rowSelected", items);
    },

    refresh(item) {
      this.$emit("refresh", item);
    },
    saveDed(item) {
      item.save();
    },
  },
};
</script>
