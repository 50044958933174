<template>
  
    <s-crud 
    title="Detalle"
    :config="configVariedades"
    @save="save($event)"
    edit
    add
    remove   
    :filter="filter"
    search-input  
    ref="crud"
    height="180"
  >
    <template scope="props">
      <!--  <v-container class="pb-0"> -->
      <v-container>
        <v-row>
          <!-- <v-col cols="10" class="s-col-form" lg="4">
            <s-select
              v-model="props.item.SpwdDay"
              label="Dia"
              :items="[
                { text: 'Lunes', value: 'Lunes' },
                { text: 'Martes', value: 'Martes' },
                { text: 'Miercoles', value: 'Miercoles' },
                { text: 'Jueves', value: 'Jueves' },
                { text: 'Viernes', value: 'Viernes' },
                { text: 'Sabado', value: 'Sabado' },
                { text: 'Domingo', value: 'Domingo' },
              ]"
            />
          </v-col> -->
          <v-col cols="12" class="s-col-form" lg="6" md="12" sm="12">
            <s-text v-model="props.item.SdpMonday" label="Lunes (Kg)" decimal>
            </s-text>
          </v-col>
          <v-col cols="12" class="s-col-form" lg="6" md="12" sm="12">
            <s-text v-model="props.item.SdpTuesday" label="Martes (Kg)" decimal>
            </s-text>
          </v-col>
          <v-col cols="12" class="s-col-form" lg="6" md="12" sm="12">
            <s-text v-model="props.item.SdpWednesday" label="Miercoles (Kg)" decimal>
            </s-text>
          </v-col>
          <v-col cols="12" class="s-col-form" lg="6" md="12" sm="12">
            <s-text v-model="props.item.SdpThursday" label="Jueves (Kg)" decimal>
            </s-text>
          </v-col>
          <v-col cols="12" class="s-col-form" lg="6" md="12" sm="12">
            <s-text v-model="props.item.SdpFriday" label="Viernes (Kg)" decimal>
            </s-text>
          </v-col>
          <v-col cols="12" class="s-col-form" lg="6" md="12" sm="12">
            <s-text v-model="props.item.SdpSaturday" label="Sábado (Kg)" decimal>
            </s-text>
          </v-col>
          <v-col cols="12" class="s-col-form" lg="6" md="12" sm="12">
            <s-text v-model="props.item.SdpSunday" label="Domingo (Kg)" decimal>
            </s-text>
          </v-col>
         <!--  <v-col cols="12" class="s-col-form" lg="6" md="12" sm="12">
            <s-text v-model="props.item.SdpDays" label="Días" number>
            </s-text>
          </v-col> -->
        </v-row>
      </v-container>
    </template>
    <template v-slot:SecStatus="{ row }">
      <v-chip
        style="margin: 0px"
        x-small
        :color="row.SecStatus == 1 ? 'success' : 'error'"
      >
        {{ row.SecStatus == 1 ? "Activo" : "Inactivo" }}
      </v-chip>
    </template>
  </s-crud>
</template>


<script>
import _sService from  "@/services/Sale/SleProjectWeekDetailService";


export default {
  components: {  },
  props: {
    SpwID: { type: Number, default: 0 }
  },

  data() {
    return {
      dialogEdit: false,
      configVariedades: {
        model: {
          SdpID: "ID",
          SdpDays: "int",
          SdpMonday: "decimal",
          SdpTuesday: "decimal",
          SdpWednesday: "decimal",
          SdpThursday: "decimal",
          SdpFriday: "decimal",
          SdpSaturday: "decimal",
          SdpSunday: "decimal",
        },
        service: _sService,
        headers: [
          { text: "ID", value: "SdpID"  },
          { text: "Lunes", value: "SdpMonday"  },
          { text: "Martes", value: "SdpTuesday"  },
          { text: "Miercoles", value: "SdpWednesday"  },
          { text: "Jueves", value: "SdpThursday"  },
          { text: "Viernes", value: "SdpFriday"  },
          { text: "Sábado", value: "SdpSaturday"  },
          { text: "Domingo", value: "SdpSunday"  },
          //{ text: "Estado", value: "SecStatus", },
        ]
      },
      filter: {}
    };
  },

  methods: {
   
 Initialize() {
   //DedID: this.DedID -- base de datos: campo actual
       this.filter = {SpwID: this.SpwID};
     
    },

    save(item) {
      
      item.SpwID = this.SpwID;
      item.save();
    },
  },

  watch: {
    SpwID() {
      this.Initialize();
    },
  },

  created() {
    this.filter = { SpwID: 0 };
  },
};
</script>
