import Service from "../Service";

const resource = "packingboat/";

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID },
        });
    },


    daylist(Week, requestID) {
        return Service.post(resource + "daylist", {}, {
            params: { Week, requestID },
        });
    },

}