import Service from "../Service";
import Vue from "vue";
const resource = "sleProjectionWeek/";

export default {
    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID },
        });
    },

    save(Prw, requestID) {
        return Service.post(resource + "save", Prw, {
            params: { requestID }
        });
    },

    
}